<template>
  <v-dialog v-model="show" persistent width="700">
    <v-card>
      <v-toolbar dense dark flat tile color="primary">
        <v-toolbar-title>
          <span>Crear Nueva Emisión</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <p class="mt-3">
          Al parecer el número de teléfono que ha hecho la llamada no existe
          dentro de nuestra base de clientes o leads. Por lo que aquí puedes
          crear la emisión correspondiente.
        </p>
        <v-form v-model="isFormValid" ref="form" lazy-validation class="mt-4">
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="formData.nombre"
                dense
                outlined
                label="Nombre(s)"
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="formData.apellido_paterno"
                dense
                outlined
                label="Apellido Paterno"
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="formData.apellido_materno"
                dense
                outlined
                label="Apellido Materno"
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="formData.fecha_nacimiento"
                dense
                outlined
                type="date"
                label="Fecha de Nacimiento"
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="formData.telefono"
                dense
                outlined
                label="Celular"
                type="number"
                maxLength="10"
                :rules="[$rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="formData.correo_electronico"
                dense
                outlined
                label="Correo Electrónico"
                :rules="[$rules.required, $rules.email]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="formData.genero"
                :items="generosList"
                dense
                outlined
                label="Género"
                :rules="[$rules.required]"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="formData.rfc"
                dense
                outlined
                label="RFC"
                :rules="[$rules.required, $rules.rfc]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="binSelected"
                :items="tarjetas"
                dense
                outlined
                label="Bin de Tarjeta"
                item-value="bin"
                item-text="nombre"
                :rules="[$rules.required]"
              >
                <template #item="{item}">
                  <span>{{ getItemBin(item.bin) }} - {{ item.nombre }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-form>
        <v-card v-if="producto" color="#f3f3f3" flat>
          <v-card-title class="text--secondary"
            >Información del certificado</v-card-title
          >
          <v-row dense>
            <v-col cols="12" md="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Plan</v-list-item-subtitle>
                  <v-list-item-title>{{ producto.nombre }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="6">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Pago Mensual</v-list-item-subtitle>
                  <v-list-item-title>{{
                    producto.subsecuentePago
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card>

        <v-row dense class="mt-3">
          <v-col cols="12">
            <v-btn
              color="primary"
              block
              :loading="loading"
              :disabled="!isFormValid"
              @click="savePolicy"
            >
              <v-icon small class="mr-2">
                mdi-content-save
              </v-icon>
              <span>Guardar Emisión</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mainAxios } from "../../mainAxios";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      isFormValid: false,
      formData: {
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        fecha_nacimiento: "",
        telefono: "",
        correo_electronico: "",
        genero: "",
        rfc: "",
        tipo_cliente: "Titular",
        tipo_persona: "Física",
        anios: 1,
        pago_id: 4,
        checkGetPolizaAseguradora: true,
        producto_id: null,
        origen: "Inbound",
        tipo_poliza: "Nueva",
      },
      binSelected: null,
      generosList: ["Masculino", "Femenino"],
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      tarjetas: [],
      producto: null,
    };
  },

  watch: {
    binSelected(v) {
      !!v && this.loadProductByBin();
    },
  },

  mounted() {
    this.loadTarjetas();
  },

  methods: {
    getItemBin(bin) {
      return bin.replaceAll("'", "");
    },

    loadTarjetas() {
      mainAxios
        .post("/v1/socioTarjetas/list", {}, this.config)
        .then((response) => {
          this.tarjetas = response.data.data.tarjetas;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    loadProductByBin() {
      this.loading = true;
      mainAxios
        .post(
          "/v1/productos/list/filtros",
          {
            ramo: "ASISTENCIAS",
            bin: this.binSelected,
          },
          this.config
        )
        .then((response) => {
          if (response.data.data.productos.length) {
            this.producto = response.data.data.productos[0];
            this.formData.producto_id = this.producto.id;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async savePolicy() {
      await this.$refs.form.validate();
      if (!this.isFormValid) return;

      this.loading = true;
      mainAxios
        .post("/v1/emision/manual", this.formData, this.config)
        .then((response) => {
          // Policy saved
          const policyId = response.data.id;
          this.$router.push(`/emision/${policyId}`);
          this.$emit("close");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
